
import {Vue, Component} from 'vue-property-decorator';
import SettingsWrapper from '@/components/settings/SettingsWrapper.vue';
import AppTopBar from '@/components/UI/AppTopBar.vue';
import MenuTop from '@/components/MenuTop.vue';
import AppStore from '@/components/AppStore';
import Http from '@/Http';
import Token from '@/components/models/Token';

@Component({
  components: {MenuTop, AppTopBar, SettingsWrapper}
})
export default class integrationView extends Vue {
  loading = false;
  createBtnLoading = false;

  tokens: Token[] = [];

  created() {
    this.getTokens();
  }

  copyClipboard(value: string) {
    navigator.clipboard.writeText(value);
    AppStore.snackbarOpenSuccess('Токен скопирован.');
  }

  getTokens() {
    this.loading = true;
    Http.get('settings/integration/tokens').then((response: any) => {
      this.tokens = response;
    }).finally(() => {
      this.loading = false;
    }).catch((HttpError) => {
      Http.systemError(HttpError.error);
    });
  }

  remoteToken(uuid: string) {
    this.loading = true;
    Http.post('settings/integration/tokens/remote', {uuid}).then((response: any) => {
      AppStore.snackbarOpenSuccess('Токен успешно удален.');
      this.getTokens();
    }).finally(() => {
    }).catch((HttpError) => {
      Http.systemError(HttpError.error);
    });
  }

  createToken() {
    this.createBtnLoading = true;
    Http.post('settings/integration/tokens/create', {}).then((response: any) => {
      AppStore.snackbarOpenSuccess('Токен успешно создан.');
      this.getTokens();
    }).finally(() => {
      this.createBtnLoading = false;
    }).catch((HttpError) => {
      Http.systemError(HttpError.error);
    });
  }


}


